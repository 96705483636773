import React, { useEffect } from "react";

const Home = () => {

  useEffect(() => {
    window.location = "https://code-clean.com"
  }, [])

  return (
    <></>
  );
};

export default Home;
