import useFetch from './useFetch'
import { useState } from 'react'
import useSWR from 'swr'

/**
 * fetcher
 * const fetcher: (url: any, method?: string, body?: null, blobFile?: boolean, customOptions?: null, useCacheEndpoint?: boolean) => Promise<any>
 * customOptions = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      cache: 'no-cache',
      body: JSON.stringify(body)
    }
 */

const useTransaction = (callApi = false, findOne = false, props) => {
  const { fetcher } = useFetch()
  const [isLoading, setIsLoading] = useState(false)

  const createTransaction = async (body) => {
    try {
      setIsLoading(true)
      const response = await fetcher(`private/transaction`, 'POST', body)

      if (!response?.info?.ok) {
        throw response
      }
      
      return response
    } catch (err) {
      return err
    } finally {
      setIsLoading(false)
    }
  }

  return { isLoading, createTransaction }
}

export default useTransaction
