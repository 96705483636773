import WashMachineIcon from '../assets/images/washing-machine.png'
import DryMachineIcon from '../assets/images/dry-machine.png'

const MachineNotIDLE = props => {
  const { machineType, machine } = props
  return (
    <div className="w-full h-screen flex items-center justify-center">
      <div className="flex flex-col self-stretch items-center justify-center gap-4">
        <img src={machineType === 'DRY' ? DryMachineIcon : WashMachineIcon} width={48} alt="washing-machine" />

        <div className="flex flex-col items-center justify-center gap-2">
          <div className="flex gap-2 items-center self-stretch">
            <div className="flex items-center font-medium text-base text-black gap-2">
              {machineType === 'DRY' ? 'เครื่องอบผ้า' : 'เครื่องซักผ้า'}{' '}
              <div
                className={`px-3 py-0.5 ${
                  machineType === 'DRY' ? 'bg-orange-400' : 'bg-blue-500'
                } rounded-md text-white`}>
                {machine?.machine?.code}
              </div>
            </div>
            <div className="divider divider-horizontal mx-0"></div>
            <div className="flex items-center font-medium text-base text-black gap-2">
              น้ำหนัก{' '}
              <div
                className={`px-3 py-0.5 ${
                  machineType === 'DRY' ? 'bg-orange-400' : 'bg-blue-500'
                } rounded-md text-white`}>
                {machine?.machine?.size}
              </div>{' '}
              Kg.
            </div>
          </div>
          <div className="text-center">
            <span className="text-zinc-500 text-xs font-medium">สาขา :</span>
            <span className="text-black text-xs font-medium">{machine?.branch?.name}</span>
          </div>
        </div>

        <div className="flex flex-col gap-1">
          <div className="text-center text-red-500 text-xl font-medium">เครื่องยังไม่พร้อมใช้งาน</div>
          <div className="text-center text-red-500 text-sm font-medium">โปรดรอซักครู่</div>
        </div>
      </div>
    </div>
  )
}

export default MachineNotIDLE
