const TextField = (props) => {
  const {
    type,
    prependIcon,
    appendIcon,
    placeholder,
    validate,
    labelValidate,
    onChange,
    onKeyDown,
    disabled,
    readOnly,
    className,
    labelTopLeft,
    labelTopRight,
    labelBottomLeft,
    labelBottomRight,
    value,
    min,
    max,
    maxLength,
    autoFocus
  } = props

  return (
    <>
      <div className={`form-control w-full relative ${className}`}>
        {prependIcon ? <div className="absolute flex left-3 w-auto h-full items-center justify-center">{prependIcon}</div> : ''}
        {labelTopLeft || labelTopRight ? (
          <label className="label pt-0">
            {labelTopLeft ? <span className="label-text">{labelTopLeft}</span> : ''}
            {labelTopRight ? <span className="label-text-alt">{labelTopRight}</span> : ''}
          </label>
        ) : ''}
        <input
          type={type || 'text'}
          placeholder={placeholder}
          className={`input input-bordered input-neutral-content ${validate ? 'input-error' : ''} w-full ${prependIcon ? 'pl-10' : ''} ${
            appendIcon ? 'pr-10' : ''
          }`}
          onChange={e => onChange ? onChange(e) : {}}
          disabled={disabled}
          readOnly={readOnly}
          onKeyDown={e => onKeyDown ? onKeyDown(e) : {}}
          min={min}
          max={max}
          value={value}
          maxLength={maxLength}
          autoFocus={autoFocus}
        />
        {labelValidate || labelBottomLeft || labelBottomRight ? (
          <label className="label pb-0">
            {validate ? <span className="text-error label-text-alt">{labelValidate}</span> : ''}
            {!validate && labelBottomLeft ? <span className="label-text-alt">{labelBottomLeft}</span> : ''}
            {labelBottomRight ? <span className="label-text-alt">{labelBottomRight}</span> : ''}
          </label>
        ) : ''}
        {appendIcon ? (
          <div className="absolute flex w-auto right-3 h-full items-center justify-center">
            {appendIcon}
          </div>
        ) : ''}
      </div>
    </>
  )
}

export default TextField
