const PriceDetail = (props) => {
  const { incExtendDry, discountAmount, transaction, sumAmount, timeLeft } = props

  return (
    <div className="w-full px-6 py-4 bg-stone-200 flex-col justify-start items-center inline-flex">
      <div className="flex flex-col self-stretch gap-2">
        {!incExtendDry && (
          <>
            {(discountAmount > 0 || transaction?.extend_dry > 0) && (
              <div className="flex flex-col self-stretch">
                <div className="flex justify-between items-center">
                  <div className="text-center text-neutral-800 text-sm font-medium">ราคา</div>
                  <div className="text-center text-neutral-800 text-sm font-medium">{transaction?.full_amount} บาท</div>
                </div>
                {discountAmount > 0 && (
                  <div className="flex justify-between items-center">
                    <div className="text-center text-success text-xs font-medium">ส่วนลดโปรโมชั่น</div>
                    <div className="text-center text-green-500 text-xs font-medium">- {discountAmount} บาท</div>
                  </div>
                )}
                {transaction?.coupon_value > 0 && (
                  <div className="flex justify-between items-center">
                    <div className="text-center text-success text-xs font-medium">ส่วนลดคูปอง</div>
                    <div className="text-center text-green-500 text-xs font-medium">
                      - {transaction?.coupon_value} บาท
                    </div>
                  </div>
                )}
                {transaction?.extend_dry > 0 && (
                  <div className="flex justify-between items-center">
                    <div className="text-center text-info text-xs font-medium">เพิ่มเวลาอบผ้า</div>
                    <div className="text-center text-blue-500 text-xs font-medium">+ {transaction?.extend_dry} บาท</div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
        <div className="flex justify-between items-center">
          <div className="text-center text-neutral-800 text-sm font-medium">ยอดเงินที่ต้องชำระ</div>
          <div className="text-center text-green-500 text-sm font-medium">{sumAmount} บาท</div>
        </div>
        <div className="flex justify-between items-center">
          <div className="text-center text-neutral-800 text-sm font-medium">กรุณาชำระเงินภายใน</div>
          <div className="text-center text-red-500 text-sm font-medium">{timeLeft}</div>
        </div>
      </div>
    </div>
  )
}

export default PriceDetail
