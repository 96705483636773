const OceanWave = () => {
  return (
    <div className="ocean">
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
    </div>
  )
}

export default OceanWave
