import Button from '../Button'
import { MdCenterFocusWeak } from 'react-icons/md'
import TrueMoneyWallet from '../../assets/images/true-money-wallet.png'
import LinePayWallet from '../../assets/images/line-pay-wallet.png'

const VerifyButtonGroup = props => {
  const {
    verify,
    verifyDisabled,
    verifyRemainingTime,
    verifyLoading,
    success,
    onVerifyTransaction,
    onCloseModal,
    type,
    saveToImage
  } = props

  const onOpenDeepLink = () => {
    // Object.assign(document.createElement('a'), {
    //   target: '_blank',
    //   rel: 'noopener noreferrer',
    //   href: `https://services.tiktokshop.com/open/authorize?service_id=${TIKTOK_SERVICE_ID}`
    // }).click()
  }

  return (
    // <div className="absolute bottom-0 w-full px-6 py-4 bg-stone-200 flex-col justify-start items-center inline-flex">
    <div className="w-full px-6 py-4 bg-stone-200 flex-col justify-start items-center inline-flex">
      <div className="flex flex-col gap-2 self-stretch">
        {!verify ? (
          <>
            <Button
              className="btn btn-ghost btn-sm"
              prependIcon={<MdCenterFocusWeak className="w-5 h-5" />}
              label="บันทึกภาพหน้าจอ เพื่อชำระเงิน"
              labelClassName="text-neutral-800 text-sm font-normal"
              onClick={() => saveToImage()}
            />
            {/* {type === 'TRUEMONEY' && (
              <>
                <div className="text-center text-zinc-500 text-xs font-medium">หรือคลิกที่นี่</div>
                <Button
                  className="btn btn-orange-200 btn-sm"
                  label={<img src={TrueMoneyWallet} height={20} alt="truemoney-wallet-button" />}
                  onClick={() => onOpenDeepLink()}
                />
              </>
            )}
            {type === 'LINEPAY' && (
              <>
                <div className="text-center text-zinc-500 text-xs font-medium">หรือคลิกที่นี่</div>
                <Button
                  className="btn btn-green-500 btn-sm"
                  label={<img src={LinePayWallet} height={20} alt="line-pay-wallet-button" />}
                  onClick={() => onOpenDeepLink()}
                />
              </>
            )} */}
            <Button
              className="btn btn-warning btn-sm"
              label={verifyDisabled ? `(${verifyRemainingTime}) ตรวจสอบการชำระเงิน` : 'ตรวจสอบการชำระเงิน'}
              labelClassName="text-neutral-800 text-sm font-medium"
              onClick={() => onVerifyTransaction()}
              disabled={verifyLoading || verifyDisabled}
              loading={verifyLoading}
            />
          </>
        ) : (
          <>
            {!verifyLoading ? (
              <>
                {/* <Button
                  className="btn btn-ghost btn-sm"
                  prependIcon={<MdCenterFocusWeak className="w-5 h-5" />}
                  label="บันทึกภาพหน้าจอ"
                  labelClassName="text-neutral-800 text-sm font-normal"
                  onClick={() => saveToImage()}
                /> */}
                {!success && (
                  <>
                    {/* <Button
                            className="btn btn-warning btn-sm"
                            label="ติดต่อเจ้าหน้าที่"
                            labelClassName="text-neutral-800 text-sm font-normal"
                          /> */}
                    <Button
                      className="btn btn-warning btn-sm"
                      label="ตรวจสอบการชำระเงิน"
                      labelClassName="text-neutral-800 text-sm font-medium"
                      onClick={() => onVerifyTransaction()}
                      disabled={verifyLoading}
                      loading={verifyLoading}
                    />
                  </>
                )}
                {/* <Button
                  className="btn btn-warning btn-sm"
                  label={verifyDisabled ? `(${verifyRemainingTime}) ตรวจสอบการชำระเงิน` : 'ตรวจสอบการชำระเงิน'}
                  labelClassName="text-neutral-800 text-sm font-medium"
                  onClick={() => onVerifyTransaction()}
                  disabled={verifyLoading || verifyDisabled}
                  loading={verifyLoading}
                /> */}
                <Button
                  className="btn btn-error btn-sm"
                  label="ปิดหน้านี้"
                  labelClassName="text-neutral-800 text-sm font-normal"
                  onClick={() => onCloseModal()}
                />
              </>
            ) : (
              <>
                <div className="w-full skeleton h-8"></div>
                <div className="w-full skeleton h-8"></div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default VerifyButtonGroup
