import { Routes, Route } from 'react-router-dom'
import './App.css'
import Wash from './page/wash/Page'
import Dry from './page/dry/Page'
import Home from './page/Home'
import Thank from './page/Thank'

function App() {
  return (
    <>
      <div className=" max-w-4xl w-full min-h-[100vh]  mx-auto ">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/wash/:branchid/:machineid" element={<Wash />} />
          <Route path="/dry/:branchid/:machineid" element={<Dry />} />
          <Route path="/thank-you" element={<Thank />} />
        </Routes>
      </div>
    </>
  )
}

export default App
