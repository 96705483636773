import useFetch from './useFetch'
import { useState } from 'react'
import useSWR from 'swr'

/**
 * fetcher
 * const fetcher: (url: any, method?: string, body?: null, blobFile?: boolean, customOptions?: null, useCacheEndpoint?: boolean) => Promise<any>
 * customOptions = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      cache: 'no-cache',
      body: JSON.stringify(body)
    }
 */

const usePayment = (callApi = false, findOne = false, props) => {
  const { getHeader, fetcher } = useFetch()
  const [isLoading, setIsLoading] = useState(false)

  const generatePaymentQR = async (token, channel, body) => {
    let customOptions = {
      method: 'POST',
      headers: getHeader(token),
      cache: 'no-cache'
    }

    if (body) {
      customOptions.body = JSON.stringify(body)
    }
    try {
      setIsLoading(true)
      const response = await fetcher(`private/payment/generate-qr/${channel}`, 'POST', body, false, customOptions)

      if (!response?.info?.ok) {
        throw response
      }

      return response
    } catch (err) {
      return err
    } finally {
      setIsLoading(false)
    }
  }

  const verifyTransaction = async (token, channel, transactionRef, isExtendDry = false) => {
    const customOptions = {
      method: 'GET',
      headers: getHeader(token),
      cache: 'no-cache'
    }
    try {
      setIsLoading(true)
      const response = await fetcher(`private/payment/verify/${channel}/${transactionRef}${isExtendDry ? '?is_extend_dry=true' : ''}`, 'GET', null, false, customOptions)

      if (!response?.info?.ok) {
        throw response
      }

      return response
    } catch (err) {
      return err
    } finally {
      setIsLoading(false)
    }
  }

  const freeTransaction = async (token, transactionRef) => {
    const customOptions = {
      method: 'POST',
      headers: getHeader(token),
      cache: 'no-cache'
    }
    try {
      setIsLoading(true)
      const response = await fetcher(`private/payment/callback/${transactionRef}/express-free`, 'POST', null, false, customOptions)

      if (!response?.info?.ok) {
        throw response
      }

      return response
    } catch (err) {
      return err
    } finally {
      setIsLoading(false)
    }
  }

  return { isLoading, generatePaymentQR, verifyTransaction, freeTransaction }
}

export default usePayment
