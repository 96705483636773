function Button(props) {
  const { type, prependIcon, appendIcon, className, label, labelClassName, disabled, loading, onClick } = props

  return (
    <button type={type || 'button'} disabled={disabled || loading} className={className} onClick={onClick}>
      {loading ? (<span className="loading loading-spinner"></span>) : (
        <div className="flex self-stretch items-center justify-center gap-2">
          {prependIcon ? prependIcon : ''}
          <div className={labelClassName}>{label}</div>
          {appendIcon ? appendIcon : ''}
        </div>
      )}
    </button>
  )
}

export default Button