import { MdHome } from 'react-icons/md'
import Button from '../components/Button'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

const Thank = () => {
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      navigate('/')
    }, 10000)
  }, [])
  return (
    <div className="w-full px-10 py-16 flex flex-col gap-6">
      <div className="relative min-h-[370px] md:min-h-[800px] lg:min-h-[920px]">
        <div className="flex justify-start md:pl-10">
          <img src="content-mascot.png" className="w-[5.5rem] md:w-[8rem]" alt="content-mascot" />
        </div>
        <div className="absolute top-[5rem] md:top-[7.5rem]">
          <img src="content-ty.png" className="" alt="content-thank-you" />
        </div>
        <div className="bg-[#2FCC59] rounded-lg px-4 py-2 absolute top-0 left-[6.5rem] md:left-[11.5rem] md:text-xl md:px-8 md:py-6 text-white">
          เครื่องกำลังทำงาน ขอบคุณที่ใช้บริการครับ
        </div>
      </div>
    </div>
  )
}

export default Thank
