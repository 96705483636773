import { useEffect, useState } from 'react'
import thaiQrLogo from '../../assets/images/thai-qr-inner.png';
import trueMoneyQrLogo from '../../assets/images/true-money-inner.png';

const QrPayImage = (props) => {
  const { src, width, height, type } = props

  const [innerLogo, setInnerLogo] = useState(null)

  useEffect(() => {
    switch (type) {
      case 'PROMPTPAY':
        setInnerLogo(thaiQrLogo)
        break
      case 'TRUEMONEY':
        setInnerLogo(trueMoneyQrLogo)
        break
      default:
        setInnerLogo(null)
        break
    }
  }, [type])

  return (
    <div className="outer">
      {!src ? (
        <></>
      ) : (
        <>
          {innerLogo && (
            <img className="inner" src={innerLogo} alt=""></img>
          )}
          <img className="qr" height={height || 350} width={width || 350} src={src} alt="logo" />
        </>
      )}
    </div>
  )
}

export default QrPayImage