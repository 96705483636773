import { useCallback, useEffect, useState } from 'react'
import WashMachineIcon from '../assets/images/washing-machine.png'
import DryMachineIcon from '../assets/images/dry-machine.png'

const MachineDetailSection = (props) => {
  const { machineType, machine, selectedProgram, extraPriceMachine } = props

  const [price, setPrice] = useState(0)

  const calculatePrice = useCallback(() => {
    setPrice((selectedProgram?.price || 0) + (extraPriceMachine || 0))
  }, [extraPriceMachine, selectedProgram?.price])

  useEffect(() => {
    calculatePrice()
  }, [calculatePrice])

  return (
    <div className="w-full bg-[#EEECDF] rounded-b-2xl p-4">
      <div className="flex flex-col self-stretch items-center justify-center gap-4">
        <img src={machineType === 'DRY' ? DryMachineIcon : WashMachineIcon} width={48} alt="washing-machine" />

        <div className="flex flex-col items-center justify-center gap-2">
          <div className="flex gap-2 items-center self-stretch">
            <div className="flex items-center font-medium text-base text-black gap-2">
              {machineType === 'DRY' ? 'เครื่องอบผ้า' : 'เครื่องซักผ้า'} <div className={`px-3 py-0.5 ${machineType === 'DRY' ? 'bg-orange-400' : 'bg-blue-500'} rounded-md text-white`}>{machine?.machine?.code}</div>
            </div>
            <div className="divider divider-horizontal mx-0"></div>
            <div className="flex items-center font-medium text-base text-black gap-2">
              น้ำหนัก <div className={`px-3 py-0.5 ${machineType === 'DRY' ? 'bg-orange-400' : 'bg-blue-500'} rounded-md text-white`}>{machine?.machine?.size}</div> Kg.
            </div>
          </div>
          <div className="text-center">
            <span className="text-zinc-500 text-xs font-medium">สาขา : </span>
            <span className="text-black text-xs font-medium">{machine?.branch?.name}</span>
          </div>
        </div>
        {selectedProgram ? (
          <div className="text-center text-red-500 text-xl">
            <span className="font-medium">ราคา : </span>
            <span className="font-semibold">{price} บาท</span>
          </div>
        ) : (<></>)}
      </div>
    </div>
  )
}

export default MachineDetailSection
