import useFetch from './useFetch'
import { useState } from 'react'
import useSWR from 'swr'

/**
 * fetcher
 * const fetcher: (url: any, method?: string, body?: null, blobFile?: boolean, customOptions?: null, useCacheEndpoint?: boolean) => Promise<any>
 * customOptions = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      cache: 'no-cache',
      body: JSON.stringify(body)
    }
 */

const useBranch = (callApi = false, findOne = false, props) => {
  const { getHeader, fetcher } = useFetch()
  const [isLoading, setIsLoading] = useState(false)

  let queryString = `?page_number=${props?.pageNumber}&page_size=${props?.pageSize}`
  queryString += props?.orderBy ? props?.orderBy : ''
  queryString += props?.filter ? props?.filter : ''

  let swrUrl = findOne ? `public/branch/${props?.id}` : `public/branch/${props?.id}${queryString}`

  const {
    data,
    isLoading: dataLoading,
    mutate,
    error
  } = useSWR(callApi ? swrUrl : null, url => fetcher(url, 'GET'), {
    // revalidateOnFocus: false,
    // revalidateOnReconnect: false,
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
      if (error.status === 404) return
    }
  })

  const getBranch = async id => {
    try {
      setIsLoading(true)
      const response = await fetcher(`public/branch/${id}`, 'GET')
      return response
    } catch (err) {
      return err
    } finally {
      setIsLoading(false)
    }
  }

  const getBranchAndMachine = async (branchId, machineId) => {
    let response = {
      branch: null,
      machine: null
    }
    try {
      setIsLoading(true)
      const branch = await getBranch(branchId)
      if (branch?._id) {
        response.branch = branch
      }
      let machine = null
      if (branch?.machines && branch?.machines?.length > 0) {
        machine = branch?.machines.find(machine => machine._id === machineId)
        machine.pricings = machine.pricings.sort((a, b) => {
          const ORDER = {
            LOW: 1,
            MED: 2,
            HIGH: 3,
            COLD: 4,
            WARM: 5,
            HOT: 6
          }
          return (ORDER[a.program] || 0) - (ORDER[b.program] || 0)
        })
        response.machine = machine
      }

      return response
    } catch (err) {
      return err
    } finally {
      setIsLoading(false)
    }
  }

  const getMachineStatus = async (branchId, machineId) => {
    try {

      let path = 'private/branch/machine/status'

      let paramsList = [`branch_id=${branchId}`, `machine_id=${machineId}`]

      if (paramsList.length > 0) {
        path += `?${paramsList.join("&")}`;
      }

      setIsLoading(true)
      const response = await fetcher(path, 'GET')
      return response
    } catch (err) {
      return err.info
    } finally {
      setIsLoading(false)
    }
  }

  const getValidateBranchByCode = async (branchCode) => {
    setIsLoading(true)
    try {
      const response = await fetcher(`public/branch/non-franchise/${branchCode}`, 'GET')
      return response
    } catch (err) {
      return err.info
    } finally {
      setIsLoading(false)
    }
  }

  return { data, dataLoading, error, mutate, isLoading, getBranch, getBranchAndMachine, getMachineStatus, getValidateBranchByCode }
}

export default useBranch
