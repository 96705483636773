import WashMachineIcon from '../assets/images/washing-machine.png'
import DryMachineIcon from '../assets/images/dry-machine.png'
import OceanWave from './OceanWave'

const MachineNotFound = props => {
  const { machineType } = props
  return (
    <>
      <div className="w-full h-screen flex items-center justify-center">
        <div className="flex flex-col self-stretch items-center justify-center gap-4">
          <img src={machineType === 'DRY' ? DryMachineIcon : WashMachineIcon} width={48} alt="washing-machine" />
          <div className="text-center text-red-500 text-xl font-medium">{machineType === 'DRY' ? 'ไม่พบข้อมูลเครื่องอบผ้า' : 'ไม่พบข้อมูลเครื่องซักผ้า'}</div>
        </div>
      </div>

      <OceanWave />
    </>
  )
}

export default MachineNotFound
