const API_ENDPOINT = process.env.REACT_APP_API_URL
const CACHE_API_ENDPOINT = process.env.REACT_APP_CACHE_API_URL
const TOKEN = process.env.REACT_APP_ACCESS_TOKEN

const useFetch = () => {
  const getHeader = token => {
    let headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${TOKEN}`
    }

    if (token) {
      headers['cf-turnstile-response'] = token
    }

    return headers
  }

  const fetcher = async (
    url,
    method = 'GET',
    body = null,
    blobFile = false,
    customOptions = null,
    useCacheEndpoint = false
  ) => {
    let options = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${TOKEN}`
      },
      cache: 'no-cache',
      body: JSON.stringify(body)
    }

    if (!body) {
      delete options.body
    }

    if (customOptions) {
      options = customOptions
    }

    const endpoint = useCacheEndpoint ? CACHE_API_ENDPOINT : API_ENDPOINT

    try {
      const response = await fetch(`${endpoint}/${url}`, options)
      if (response.status === 404) {
        return null
      }

      if (!response.ok) {
        let error = new ErrorEvent('An error occurred while fetching the data.')
        error.info = response
        error.status = response.status
        throw error
      }

      if (blobFile) {
        return await response.blob()
      } else {
        return await response.json()
      }
    } catch (err) {
      return err
    }
  }

  return {
    getHeader,
    fetcher
  }
}

export default useFetch
